<template>
    <div>
		<div v-if="languageSelector" class=" sticky top-0 dark:sticy-header-background">
            <Header :language="lang" noPin :back="false" @modeChanged="modeChanged"/>
            <div class="text-center mt-64">
                <img :src="logo" alt="Logo" class="w-44 mx-auto">
                <h2 class="font-heading text-h4 text-sepiaBlack dark:text-white">{{ $t('language.selectTitle') }}</h2>
                <Language flag tv :language="lang" @langchanged="langchanged_" class="my-4" v-on:click="selectorChange" />
                <p class="font-body text-bodyL text-sepiaBlack dark:text-white px-6">{{ $t('language.text') }}</p>
            </div>
    	</div>
        <div v-else class="h-screen w-tv-main-axis mx-auto text-center relative" v-on:click="click" >
            <Header :language="lang" 
				@langchanged="langchanged_"
				noPin
                :back="false"/>
            <div class="flex flex-col justify-center align-center h-4/5">
                <div class="hidden mx-auto" id="errorM">
                    <SystemMessage :text="errorMsg" type='error' />
                </div>
                <h1 class="font-heading text-h1 text-redDamask pt-24">{{ $t('timeline.pinCheckTitle') }}</h1>
                <p class="font-body text-bodyXL text-sepiaBlack dark:text-halfColonialWhite mb-4">{{ $t('timeline.pinCheckSubtitle') }}</p>
                <PinCodeInput @pinChange="pinChanged" @focusChange="focusChange" :inputValueProp1="inputValue1" :inputValueProp2="inputValue2" :inputValueProp3="inputValue3" :inputValueProp4="inputValue4"/>
                <div class="flex justify-center gap-2 mt-10">
                    <Button buttonType='primary' :text="$t('timeline.pinCheckBtn')" :disabledBtn="btnDisabel" v-on:click="pinCheck()"/>
                </div>
            </div>
            <div v-if="device == 'tv'" class="grid grid-cols-3 gap-2 mt-10 mb-4 w-52 mx-auto">
                <Button buttonType='secondary' text="1" :disabledBtn="btnDisabel" v-on:click="input(1)" />
                <Button buttonType='secondary' text="2" :disabledBtn="btnDisabel" v-on:click="input(2)" />
                <Button buttonType='secondary' text="3" :disabledBtn="btnDisabel" v-on:click="input(3)" />
                <Button buttonType='secondary' text="4" :disabledBtn="btnDisabel" v-on:click="input(4)" />
                <Button buttonType='secondary' text="5" :disabledBtn="btnDisabel" v-on:click="input(5)" />
                <Button buttonType='secondary' text="6" :disabledBtn="btnDisabel" v-on:click="input(6)" />
                <Button buttonType='secondary' text="7" :disabledBtn="btnDisabel" v-on:click="input(7)" />
                <Button buttonType='secondary' text="8" :disabledBtn="btnDisabel" v-on:click="input(8)" />
                <Button buttonType='secondary' text="9" :disabledBtn="btnDisabel" v-on:click="input(9)" />
                <Button buttonType='secondary' text="0" class="col-start-2" :disabledBtn="btnDisabel" v-on:click="input(0)" />
                <Button buttonType='secondary' text="<-" class="col-start-3" :disabledBtn="btnDisabel" v-on:click="input('')" />

            </div>
        </div>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Button from '@/components/Button.vue';
import PinCodeInput from "@/components/tablet/PinCodeInput.vue";
import SystemMessage from '@/components/SystemMessage.vue';
import Language from '@/components/Language.vue';

export default {
    name: "TimeLinePin",
    components: {
        Header,
        Button,
        PinCodeInput,
        SystemMessage,
		Language,
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            id: this.$route.params.id,
            pinInput: '',
            response: {},
            seconds: 60,
			standard_sec: 60,
            device: window.innerWidth > 1200 ? 'tv' : 'tablet',
            btnDisabel: false,
            errorMsg: this.$t('timeline.pinCheckErrorMessage'),
            inputValue1: '',
            inputValue2: '',
            inputValue3: '',
            inputValue4: '',
            focus: 1,
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
			languageSelector: true,
            logo: '',
        }
    },
    mounted() {
        if (localStorage.getItem('user_pin')) {
            this.$router.push('/timelineedit');
        }
        // document.querySelector('#custom_input_' + this.focus).focus();
        this.$i18n.locale = this.lang;
        this.getLogo();
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        pinCheck() {
            this.btnDisabel = true;
            document.querySelector("#systemM").classList.remove("hidden");
            document.querySelector("#errorM").classList.add("hidden");
            const search_input = this.pinInput;
            this.axios.get(this.baseURL + `/wp-json/wp/v2/pin_check`, {params: {'user_pin': search_input}})
            .then(response => {
                const response_data = response.data;
                if (response_data.error) {
                    document.querySelector("#errorM").classList.remove("hidden");
                    this.btnDisabel = false;
                } else {
                    this.$router.push('/timelineedit?user_pin=' + search_input);
                }
            }).catch( () => {
                document.querySelector("#errorM").classList.remove("hidden");
                this.btnDisabel = false;
            });
        },
        pinChanged(pin, index, value) {
            if (index < 4 && value != '') {
                this.focus = index + 1;
            }
            this.pinInput = pin;
            switch(index) {
                case 1:
                    this.inputValue1 = value;
                    break;
                case 2:
                    this.inputValue2 = value;
                    break;
                case 3:
                    this.inputValue3 = value;
                    break;
                case 4:
                    this.inputValue4 = value;
                    break;
            }
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        startTimer() {
            this.seconds = this.standard_sec;
            setTimeout(this.countdown, 1000);
        },
        countdown() {
            if (this.seconds > 0) {
                this.seconds -= 1;
                setTimeout(this.countdown, 1000);
            } else {
				this.$router.push('/' + this.device + 's?category=' +localStorage.getItem(this.device + '_category') + '&seconds=0');
			}
        },
        click() {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        handleScroll (event) {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        input(value) {
            switch(this.focus) {
                case 1:
                    this.inputValue1 = value;
                    break;
                case 2:
                    this.inputValue2 = value;
                    break;
                case 3:
                    this.inputValue3 = value;
                    break;
                case 4:
                    this.inputValue4 = value;
                    break;
                default:
                    this.inputValue1 = value;
                    this.focus = 1;
                    break;
            }
            this.pinInput = `${this.inputValue1}${this.inputValue2}${this.inputValue3}${this.inputValue4}`;
            if (this.focus < 4 && value != '') {
                this.focus += 1;
            }
            document.querySelector('#custom_input_' + this.focus).focus();

        },
        focusChange(value) {
            this.focus = value;
        },
        langchanged_(value) {
			this.lang = value;
		},
        selectorChange() {
            this.languageSelector = false;
            document.querySelector('#custom_input_' + this.focus).focus();
        },
        getLogo: function() {
            const element = document.getElementById("app");
            if (element.classList.contains('darkMode')) {
                this.logo = require("../assets/logo_dark.png");
            } else {
                this.logo = require("../assets/logo_light.png");
            }
        },
        modeChanged(value) {
            this.getLogoAfterChange(value);
        },
        getLogoAfterChange(value) {
            if (value == 'dark') {
                this.logo = require("../assets/logo_dark.png");
            } else {
                this.logo = require("../assets/logo_light.png");
            }
        },
    },
};
</script>
